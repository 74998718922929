import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { navigate } from 'gatsby';
import * as React from 'react';
import classnames from 'classnames';
import { getPredictionResults } from "../libs/helpers";
import { tables } from "../libs/models";

const useStyles = makeStyles({
  index: {
    width: 20,
  },
  arrow: {
    width: 40,
  },
  row: {
    cursor: 'pointer',
  },
  clickHeader: {
    cursor: 'pointer',
    width: 120,
  },
  notsorted : {
    color: 'grey'
  }
});

type Sortable = 'score' | 'accumulated';

export const CurrentScore: React.FC = () => {
  const [sortBy, setSortBy] = React.useState<Sortable>('score');
  const classes = useStyles();

  const currentTable = tables[tables.length-1];

  const results = getPredictionResults(currentTable);
  results.sort((a, b) => b[sortBy] - a[sortBy]);

  const buildCell = (header: string, sort: Sortable) => {
    return (<TableCell className={classnames(classes.clickHeader, sort !== sortBy && classes.notsorted)} align="right" onClick={() => setSortBy(sort)}>
      {header}
    </TableCell>);
  };
  return (
      <TableContainer component={Paper}>
      <Table aria-label="Stillingen" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Navn</TableCell>
            {buildCell('Akkumuleret', 'accumulated')}
            {buildCell('Point', 'score')}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((row, i) => (
            <TableRow key={row.name} className={classes.row} onClick={() => {
              navigate('/person', {state:{prediction: row}})
            }}>
              <TableCell component="th" scope="row" className={classes.index}>
              {i + 1}
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell align="right">{row.accumulated}</TableCell>
              <TableCell align="right">{row.score}</TableCell>
              <TableCell align="right" className={classes.arrow}><ArrowForwardIos /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
