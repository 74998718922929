import Container from "@material-ui/core/Container";
import * as React from "react"
import { CurrentScore } from "../components/current-score";
import { Layout } from "../components/layout";

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Container>
        <h1>Stillingen</h1>
        <CurrentScore />
      </Container>
    </Layout>
  )
}

export default IndexPage
